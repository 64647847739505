<template>
  <div>
    <div class="container">
      <h1 class="text-uppercase">
        <span v-if="language === 'us'">Terms & conditions</span>
        <span v-if="language === 'mx'">Términos y Condiciones</span>
      </h1>
    </div>
    <div class="terms-head-content pb-20">
      <div class="container">
        <template v-if="language === 'us'">
          <h2>
            END USER AGREEMENT FOR<br />
            TREBEL MUSIC SERVICE<br />
            ("SERVICE")
          </h2>
          <p>
            <strong>(I)</strong> By installing, copying, or otherwise using the
            Service or its software, you acknowledge that you have read and
            understood this Agreement, and agree to be bound by its terms and
            conditions. If you do not agree to (or cannot comply with) the terms
            and conditions of this Agreement, do not install, copy, or use the
            Service, software or any music, images, video, text, or other
            material available through the Service ("Content").
          </p>

          <p>
            <strong>(II)</strong> You agree that you will not, for any reason
            whatsoever, reverse engineer, decompile, disassemble, or otherwise
            tamper with any security components, usage rules or other protection
            measures applicable to the Service or Content. You agree to abide by
            the rules and policies established from time to time by Retailer.
            Such rules and policies will be applied generally in a
            nondiscriminatory manner to users of the Service and software, and
            may include, for example, required or automated updates,
            modifications, and/or reinstallations of the software and obtaining
            available patches to address security, interoperability, and/or
            performance issues.
          </p>

          <p>
            <strong>(III)</strong> You agree not to make any use of the Content
            that would infringe the copyright therein.
          </p>

          <p>
            <strong>(IV)</strong> The Service and any related software may
            enable you to obtain, listen to, view, and/or read (as the case may
            be) Content that may be obtained by you in digital form, and you
            shall do so solely for your personal, noncommercial entertainment
            use. This Content may be owned by Retailer or by third parties.
            However, in all circumstances, you understand and acknowledge that
            your rights with respect to Content will be limited by copyright
            law. You agree that you will not attempt to modify any software or
            Content obtained through the Service for any reason whatsoever,
            including for the purpose of disguising or changing any indications
            of the ownership or source of the Content. <br /><br />Please note
            that the software may be programmed to cache or remove content from
            the Service as a way to manage storage limitations or to comply with
            applicable copyright laws.
          </p>

          <p>
            <strong>(V)</strong> You represent, warrant and agree that you are
            using the Service hereunder for your own personal, noncommercial
            entertainment use and not for redistribution or transfer of any
            kind. You agree not to redistribute, broadcast, publicly perform or
            publicly display any Content, or otherwise transfer any Content
            obtained through the Service.
          </p>

          <p>
            <strong>(VI)</strong> Retailer and/or the owners of the Content may,
            from time to time, remove Content from the Service without notice.
          </p>

          <p>
            <strong>(VII)</strong> The owners of Content are intended
            beneficiaries of this Agreement and shall have the right to enforce
            this Agreement against you.
          </p>

          <p>
            <strong>(VIII)</strong> THE SERVICE INCLUDING ALL SOFTWARE, CONTENT
            AND OTHER INFORMATION, MATERIALS AND PRODUCTS INCLUDED ON OR
            OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE ARE PROVIDED
            "AS-IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND FROM
            RETAILER OR ANY OWNERS OF CONTENT. TO THE FULL EXTENT PERMISSIBLE BY
            APPLICABLE LAW, RETAILER AND ALL OWNERS OF CONTENT DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. NEITHER RETAILER NOR ANY OWNER OF CONTENT WARRANTS THAT THE
            SERVICE OR ANY SOFTWARE, CONTENT, INFORMATION, MATERIALS OR PRODUCTS
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE
            ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          </p>
        </template>
        <template v-if="language === 'mx'">
          <h2>
            TÉRMINOS Y CONDICIONES DE USO DEL SERVICIO DE MÚSICA TREBEL (“EL
            SERVICIO”)
          </h2>

          <p>
            <strong>(I)</strong> Al instalar, copiar, o de cualquier manera usar
            El Servicio o su software, usted reconoce que ha leído y entendido
            este Acuerdo, y que acepta participar de sus términos y condiciones.
            Si no está de acuerdo (o no puede cumplir) los términos y
            condiciones de este Acuerdo, no instale, copie o use el Servicio, el
            software, o la música, las imágenes, el video, el texto o cualquier
            otro material disponible a través del Servicio (“El Contenido”).
          </p>

          <p>
            <strong>(II)</strong> Acepta que por ninguna razón tratará de hacer
            ingeniería inversa, de alterar, infringir o sondear, escanear o
            probar vulnerabilidades en el Servicio o en los sistemas de cómputo,
            la red, las reglas de uso o cualquiera de los componentes de
            seguridad de TREBEL, las medidas de autenticación o cualquier otra
            medida de protección aplicable al Servicio, el Contenido o cualquier
            parte del mismo. Acepta que seguirá las reglas y las políticas
            establecidas por el Distribuidor. Estas reglas y políticas se
            aplicarán generalmente y sin discriminación a los usuarios del
            Servicio y software, y pueden incluir, por ejemplo, actualizaciones
            y modificaciones automáticas y/o re-instalación del software y
            obtención de parches para solucionar problemas de seguridad,
            operación o funcionamiento.
          </p>

          <p>
            <strong>(III)</strong> Acepta no hacer uso de El Contenido de
            ninguna manera que infrinja los derechos de autor de este.
          </p>

          <p>
            <strong>(IV)</strong> El Servicio y cualquier software relacionado
            le podrán permitir obtener, oír, ver y/o leer (como sea el caso)
            Contenido que usted podrá obtener en forma digital y que sólo podrá
            aprovecharlo para uso personal, y entretenimiento no comercial. Este
            contenido puede pertenecer al Distribuidor o a terceros. Sin
            embargo, en todas las circunstancias, usted entiende y acepta que
            sus derechos con respecto al Contenido serán limitados por la ley de
            derechos de autor. Usted acuerda que no tratará de modificar el
            software o El Contenido obtenido a través del Servicio de ninguna
            manera y por ninguna razón, incluyendo con el propósito de encubrir
            o cambiar las indicaciones de la propiedad o la fuente de El
            Contenido.<br /><br />Por favor, tenga en cuenta que el software
            puede estar programado para almacenar o eliminar contenido del
            Servicio como una forma de manejar las limitaciones del
            almacenamiento y para acatar con las leyes de derechos de autor que
            apliquen al caso.
          </p>

          <p>
            <strong>(V)</strong> Usted declara, garantiza, y acuerda que usará
            el Servicio de ahora en adelante para su uso personal, de
            entretenimiento no comercial y no para re distribuir o transferir de
            ninguna manera. Usted acuerda no redistribuir, transmitir,
            representar públicamente o publicar El Contenido o de cualquier
            manera transferir el Contenido obtenido a través del Servicio.
          </p>

          <p>
            <strong>(VI)</strong> El distribuidor y/o los dueños del Contenido
            pueden, de vez en cuando, remover el Contenido del Servicio sin
            previo aviso.
          </p>

          <p>
            <strong>(VII)</strong> Los propietarios del Contenido son
            beneficiarios intencionales de este Acuerdo y tendrán el derecho de
            hacer valer este Acuerdo en tu contra.
          </p>

          <p>
            <strong>(VIII)</strong> ENTIENDES Y ACEPTAS QUE EL SERVICIO,
            INCLUYENDO EL SOFTWARE, CONTENIDO E INFORMACIÓN, MATERIAL O
            PRODUCTOS INCLUIDOS SE PROPORCIONAN “TAL CUAL” Y “SEGÚN
            DISPONIBILIDAD”, SIN GARANTÍA EXPRESA O IMPLÍCITA O CONDICIÓN DE
            NINGÚN TIPO DE TREBEL Y TODOS LOS PROPIETARIOS DEL CONTENIDO. DENTRO
            DEL MARGEN PERMITIDO POR LA LEY, TREBEL Y LOS PROPIETARIOS DE
            CONTENIDO RENUNCIAN A CUALQUIER GARANTÍA, EXPRESA O IMPLÍCITA,
            INCLUYENDO PERO SIN LIMITARSE A LA CONDICIÓN DE CALIDAD
            SATISFACTORIA, COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO EN
            PARTICULAR. NI TREBEL NI NINGÚN PROPIETARIO DE CONTENIDO GARANTIZAN
            QUE EL SERVICIO ESTÉ LIBRE DE MALWARE U OTROS COMPONENTES DAÑINOS.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("terms"),
      titleTemplate: "TREBEL MUSIC: %s",
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: `TREBEL MUSIC: ${this.$t("terms")}`,
        description: this.$t("meta.home.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  computed: {
    language() {
      return this.$route.query.country === "MX" ? "mx" : "us";
    },
    locale() {
      return this.$store.getters.locale;
    }
  }
};
</script>
